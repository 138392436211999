<template>
  <div>
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <div class="text-center">
      <Busy primary :size="4" />
      <p class="mt-4 mb-2 text-primary">Signing out</p>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/authentication.scss";
</style>

<script>
import CognitoAuth from "@/cognito/cognitoauth";
import Busy from "@/components/Busy";
import Console from "@/console";
export default {
  name: "OrganisationDetails",
  components: { Busy },
  created() {
    try {
      if (typeof window.UserLeap != "undefined") {
        window.UserLeap.logoutUser();
        Console.log("Signed out of userleap");
      }
    } catch (e) {
      Console.error("Failed to signout of userleap: ", e);
    }
    CognitoAuth.signout();
  },
  data() {
    return {
      title: "Sign Out"
    };
  }
};
</script>